import { message as AntdMessage } from "antd";
import { MessageInstance } from "antd/es/message/interface";

let messageInstance: MessageInstance;

// 导出一个proxy动态调用messageApi
export const message = new Proxy<MessageInstance>({} as any, {
  get(target, prop) {
    if (messageInstance) {
      return messageInstance[prop as keyof MessageInstance];
    }
    return () => {};
  },
});

export function Message() {
  const [api, contextMsgHolder] = AntdMessage.useMessage();
  messageInstance = api;
  return contextMsgHolder;
}
