import { useEffect, useRef, useState } from 'react';
import { Button, List } from 'antd';
import './NoteAgent.less';
import { AgentToolCall, execAgent, getTags } from './api';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { AudioInput } from './AudioInput';
import { WaitProcess } from './WaitProcess';
import { TypeWriter } from './TypeWriter';
import { UserAvatar } from './UserAvatar';
import { message } from '../message';
import { useLiveQuery } from 'dexie-react-hooks';
import { ChatMessage, db } from '../Data';

const MAX_MESSAGE = 10;
let controller: AbortController;
const DEFAULT_TAGS = [
  '总结一下当前笔记要点',
  '把当前笔记内容补充一下',
  '写一个工作总结',
];
let lastKey: any;

export function NoteAgent() {
  const options = useLiveQuery(() => db.agent.toCollection().first());
  const data = useLiveQuery(() =>
    db.messages.reverse().limit(MAX_MESSAGE).toArray()
  );
  const [action, setAction] = useState<AgentToolCall>();
  const [requesting, setRequesting] = useState<boolean | 'output'>(false);
  const scrollbar = useRef<Scrollbars>(null);
  const [nextTags, setTags] = useState<string[]>([]);
  const [sid, setSID] = useState(options?.sid);
  const [msgs, setMessages] = useState<ChatMessage[]>(data?.reverse() || []);

  const onSession = async (sid: string) => {
    await db.setSid(sid);
  };
  const onStarted = async (msgs: ChatMessage[]) => {
    lastKey = await db.messages.bulkAdd(msgs);
  };
  const onUpdate = async (answer: string) => {
    db.messages.update(lastKey, {
      message: answer,
      state: 'output',
    });
  };
  const onTags = async (ts: any[]) => {
    await db.messages.update(data![0].id!, {
      tags: ts,
    });
  };
  const onComplate = async (answer?: string, tags?: any[]) => {
    await db.messages.update(lastKey, {
      state: 'success',
      ...(answer
        ? {
            message: answer, // 非流模式兼容
          }
        : {}),
      ...(tags
        ? {
            tags,
          }
        : {}),
    });
  };
  const onError = async () => {
    await db.messages.delete(lastKey);
  };

  useEffect(() => {
    if (options?.sid) {
      setSID(options?.sid);
    }
  }, [options?.sid]);

  useEffect(() => {
    if (msgs?.length) {
      if (msgs[0].state === 'success') {
        if (msgs[0].tags?.length) {
          setTags(msgs[0].tags);
        } else if (sid && msgs[0].id) {
          getTags(sid).then((tags) => {
            let ts = tags.map((msg: any) => msg.text) || [];
            if (!ts.length) {
              ts = DEFAULT_TAGS;
            }
            setTags(ts);
            onTags?.(ts);
          });
        }
      }
    } else {
      setTags(DEFAULT_TAGS || []);
    }
  }, [msgs]);

  function handleStop() {
    if (controller?.signal.aborted) {
      return false;
    }
    controller?.abort();
    return !!controller;
  }

  async function handleAsk(input: string) {
    const canceled = handleStop();
    input = input.trim();
    if (!input) return;
    if (requesting && !canceled) return;
    const usermsg = {
      type: 'user',
      createdAt: new Date(),
      message: input,
    };
    const agentmsg = {
      type: 'agent',
      message: '...',
      createdAt: new Date(),
      state: 'waitting',
    };
    setMessages([...msgs!, usermsg, agentmsg]);
    setTags([]);
    await onStarted?.([usermsg, agentmsg]);
    try {
      controller = new AbortController();
      setRequesting(true);
      let answer = '';
      const res = await execAgent(
        controller,
        input,
        sid,
        async (act) => {
          setAction(act);
        },
        (msg) => {
          answer += msg;
          agentmsg.message = answer;
          agentmsg.state = 'output';
          setMessages([...msgs!, usermsg, agentmsg]);
          onUpdate?.(msg);
          setRequesting('output');
        },
        async (sid) => {
          setSID(sid);
          await onSession?.(sid);
        }
      );
      setAction(undefined);
      const tags = res.tags?.map((msg: any) => msg.text) || [];
      answer = res.answer || answer;
      agentmsg.state = 'success';
      agentmsg.message = answer;
      setMessages([...msgs!, usermsg, agentmsg]);
      setTags(tags);
      await onComplate?.(answer, tags);
      setTags(tags);
      setRequesting(false);
    } catch (err) {
      setRequesting(false);
      if (!(err as Error).toString?.().includes(' AbortError')) {
        message.error((err as any).message);
        setMessages([...msgs!]);
        await onError?.();
      } else {
        agentmsg.state = 'success';
        setMessages([...msgs!, usermsg, agentmsg]);
        await onComplate?.();
      }
    }
  }

  useEffect(() => {
    scrollbar.current?.scrollToBottom();
  }, [data]);

  return (
    <div className={'ipfs-notebook-agent-panel'}>
      <Scrollbars
        ref={scrollbar}
        autoHide
        className="ipfs-notebook-agent-answers"
      >
        <List
          className="ipfs-notebook-agent-list"
          itemLayout="horizontal"
          dataSource={msgs?.slice(0) || []}
          locale={{ emptyText: <span>还没有任何记录</span> }}
          renderItem={(item, index) => (
            <List.Item className={item.type}>
              <List.Item.Meta
                avatar={<UserAvatar type={item.type} sex={'man'}></UserAvatar>}
                description={
                  <div>
                    {item.state === 'waitting' &&
                    msgs?.length &&
                    index === msgs?.length - 1 &&
                    requesting ? (
                      <WaitProcess action={action}></WaitProcess>
                    ) : (
                      <TypeWriter
                        key={index}
                        enabled={
                          index === msgs!.length - 1 && requesting === 'output'
                        }
                        force={index < msgs!.length - 1}
                        text={item.message}
                      ></TypeWriter>
                    )}
                    {msgs?.length &&
                      index === msgs?.length - 1 &&
                      requesting && (
                        <div className="ipfs-notebook-agent-requesting">
                          <Button className="noborder" onClick={handleStop}>
                            停止回答
                          </Button>
                        </div>
                      )}
                  </div>
                }
              />
            </List.Item>
          )}
        />
        {nextTags.length > 0 && (
          <div className="ipfs-notebook-agent-qslist">
            <div className="title">
              {msgs?.length ? '接下来你还可以问:' : '可以从下面的问题开始:'}
            </div>
            {nextTags.map((txt, i) => (
              <Button
                type="link"
                key={i}
                onClick={() => {
                  handleAsk(txt);
                }}
              >
                {txt}
              </Button>
            ))}
          </div>
        )}
      </Scrollbars>
      <AudioInput
        onEnter={handleAsk} 
      ></AudioInput>
    </div>
  );
}
