import { db } from '../Data';
import { AgentToolCall, toolCallback } from './api';

export const handleToolCall = async (message: AgentToolCall) => {
  let data: any = null;
  try {
    switch (message.toolName) {
      case 'read_note':
        return (await db.getActiveNote())?.content || '未找到笔记';
      case 'write_note':
        const id = (await db.getActiveNote())?.id;
        if (!id) throw new Error('无法修改笔记');
        await db.upsertNote(message.params, id);
        return '笔记已修改成功';
      case 'exe_command':
        return '';
      case 'find_note':
        return '';
      case 'login':
        return '';
      case 'logout':
        return '';
      case 'install_desktop':
        return '';
    }
  } catch (err) {
    data = (err as Error).message || '执行失败';
  }
  await toolCallback(message.toolName, message.callId, data).catch(
    console.error
  );
};
