import Markdown from "react-markdown";
import { useEffect, useState } from "react"; 

const TIMEOUT = 40; // 每个字之间的延迟时间（毫秒）
const punctuation = "，‌。‌？‌！‌；‌,.?!;"; // 定义标点符号集合

export const TypeWriter = ({
  text,
  force = false,
  enabled = true,
}: {
  text: string;
  force?: boolean;
  enabled?: boolean;
}) => {
  const [end] = useState(enabled);
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [cur, setCur] = useState(0);
  const [last, setLast] = useState(0);

  async function checkPlay() {
    const l = Math.min(text.length, cur + 60);
    for (var i = l; i >= cur; i--) {
      if (punctuation.indexOf(text[i]) !== -1) {
        setPlaying(true);
        const next = i;

        // console.log(answer.slice(last, next));
        // await playAudio(text.slice(last, next));
        setLast(i + 1);
        setCur(l);
        setPlaying(false);
        return;
      }
    }
    setCur(text.length);
  }

  useEffect(() => {
    if (playing) return;
    if (force ? enabled : end) {
      checkPlay();
    }
  }, [playing, text, last, cur]);

  useEffect(() => {
    if (force ? enabled : end) {
      if (index < text.length) {
        const timer = setTimeout(() => {
          setDisplayText((prevText) => prevText + text[index]);
          setIndex(index + 1);
        }, TIMEOUT); // 每个字之间的延迟时间（毫秒）

        return () => clearTimeout(timer);
      }
    } else {
      setDisplayText(text);
    }
  }, [index, text]);

  return <Markdown>{displayText}</Markdown>;
};
