import { Button, Input, Popover } from 'antd';
import './NoteAgent.less';
import { useState } from 'react';

const { TextArea } = Input;

export const sendAudio = new Audio('/static/send.mp3');

/**
 * 嗖一声
 *
 * @export
 */
export function sendSuo() {
  sendAudio.pause();
  sendAudio.currentTime = 0;
  sendAudio.play();
}

export function AudioInput(props: {
  disabled?: boolean; 
  onEnter: (text: string) => Promise<void>; 
}) {
  const [input, setInput] = useState('');

  const sendClick = () => {
    sendSuo();
    props.onEnter(input);
    setInput('');
  };

  function handleInput(e: any) {
    setInput(e.target.value);
  }

  return (
    <div className={'ipfs-notebook-agent-question'}>
      <TextArea
        className={'ipfs-notebook-agent-input'}
        autoFocus
        value={input}
        autoSize={{ minRows:2, maxRows: 10 }}
        allowClear
        bordered={false}
        placeholder="按Shift+Enter换行输入"
        readOnly={props.disabled}
        onKeyDown={(e) => {
          if (props.disabled) return;
          if (e.code === 'Enter' && !(e.shiftKey || e.ctrlKey || e.altKey)) {
            e.preventDefault();
            sendSuo();
            props.onEnter(input);
            setInput('');
          }
        }}
        onChange={handleInput}
      />

      {!!input && (
        <Button
          type="primary"
          size="small"
          disabled={props.disabled}
          className="ipfs-notebook-agent-send"
          {...sendClick}
        >
          发送
        </Button>
      )}
    </div>
  );
}
