import { MessageOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

export function UserAvatar(props: {
  type: 'agent' | 'user' | string;
  sex?: 'boy' | 'girl' | string;
  speeking?: boolean;
  size?: 'small' | 'large' | number;
}) {
  const img =
    props.type === 'agent' ? (
      <Avatar src={<MessageOutlined />} size={props.size} />
    ) : (
      <Avatar src={<UserOutlined />} size={props.size} />
    );
  return (
    <span className="ipfs-notebook-agent-avatar">
      {props.speeking && (
        <SyncOutlined
          spin
          style={!props.size ? { fontSize: 32 } : {}}
          className="loading"
        />
      )}
      {img}
    </span>
  );
}
